import * as React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Trail, animated } from 'react-spring'
import Helmet from 'react-helmet';
import SliderWrapper from '../components/SliderWrapper';
import Slider from 'react-slick';
import '../assets/about.scss'

import socialImage from '../images/About_Page_Social_Media_Thumb_01.jpg'
// import heroImage from '../images/editing_suite.jpg'
import amexLogo from '../images/client_logos/v2/American_Express.png'
import anheuserLogo from '../images/client_logos/v2/Anheuser_Busch.png'
import bbdoLogo from '../images/client_logos/v2/BBDO.png'
import bioSteelLogo from '../images/client_logos/v2/BioSteel.png'
import cavalryLogo from '../images/client_logos/v2/Cavalry.png'
import chevyLogo from '../images/client_logos/v2/Chevrolet.png'
import condeNast from '../images/client_logos/v2/Conde_Nast_logo.png'
import CramerKrasseltLogo from '../images/client_logos/v2/CK_Logo.png'
import ddbLogo from '../images/client_logos/v2/DDB.png'
import designoryLogo from '../images/client_logos/v2/Designory.png'
import dhlLogo from '../images/client_logos/v2/DHL.png'
import digitasLogo from '../images/client_logos/v2/Digitas.png'
import donerLogo from '../images/client_logos/v2/Doner.png'
import discoveryFamilyLogo from '../images/client_logos/v2/Discovery_Channel_Family.png'
import doordashLogo from '../images/client_logos/v2/DoorDash.png'
import facebookLogo from '../images/client_logos/v2/Facebook.png'
import fcbLogo from '../images/client_logos/v2/FCB.png'
import fluent360Logo from '../images/client_logos/v2/Fluent360.png'
import fordLogo from '../images/client_logos/v2/Ford.png'
import googleLogo from '../images/client_logos/v2/Google.png'
import gqLogo from '../images/client_logos/v2/GQ_Fall21_12.png'
import havasLogo from '../images/client_logos/v2/Havas.png'
import hawkeyeLogo from '../images/client_logos/v2/Hawkeye.png'
import intuitLogo from '../images/client_logos/v2/Intuit.png'
import jackLogo from '../images/client_logos/v2/Jack_Daniels.png'
import leoBurnettLogo from '../images/client_logos/v2/Leo_Burnett.png'
import lyftLogo from '../images/client_logos/v2/Lyft.png'
import mcDonaldsLogo  from '../images/client_logos/v2/McDonalds.png'
import mcLogo  from '../images/client_logos/v2/Mastercard.png'
import mazdaLogo from '../images/client_logos/v2/Mazda.png'
import mcannLogo  from '../images/client_logos/v2/McCann.png'
import mms  from '../images/client_logos/v2/MMs.png'
import modeloLogo  from '../images/client_logos/v2/Modelo.png'
import monoLogo  from '../images/client_logos/v2/Mono.png'
import naturesWayLogo from '../images/client_logos/v2/NaturesWay.png'
import nbcGolfLogo from '../images/client_logos/v2/NBC_Golf_Channel.png'
import nbcLogo from '../images/client_logos/v2/NBC_Universal.png'
import nflLogo from '../images/client_logos/v2/NFL.png'
import nissanLogo from '../images/client_logos/v2/Nissan.png'
import ogilvyLogo from '../images/client_logos/v2/Ogilvy.png'
import okrpLogo from '../images/client_logos/v2/OKRP.png'
import otterLogo from '../images/client_logos/v2/Otterbox.png'
import olympusLogo from '../images/client_logos/v2/Olympus.png'
import peterMayerLogo from '../images/client_logos/v2/petermayer.png'
import publicisLogo from '../images/client_logos/v2/Publicis.png'
import razorfishLogo from '../images/client_logos/v2/Razorfish.png'
import saveTheChildrenLogo from '../images/client_logos/v2/Save_the_Children.png';
import shiseidoLogo from '../images/client_logos/v2/Shiseido.png';
import specialOlympicsLogo from '../images/client_logos/v2/Special_Olympics.png'
import spotifyLogo from '../images/client_logos/v2/Spotify.png'
import tdhLogo from '../images/client_logos/v2/TDH.png'
import teamUsa from '../images/client_logos/v2/Team_USA.png'
import vmlyrLogo from '../images/client_logos/v2/VMLYR.png'
import weberShandwick from '../images/client_logos/v2/Weber_Shandwick.png'
import whiteClaw from '../images/client_logos/v2/WhiteClaw_Fall21_29.png'
import wranglerLogo from '../images/client_logos/v2/Wrangler.png'
import wundermanThompson from '../images/client_logos/v2/Wunderman_Thompson.png'
import Zeno from '../images/client_logos/v2/Zeno.png'

//recognition logos
import adcLogo from '../images/awards/ADC.png'
import aicpLogo from '../images/awards/AICP.png'
import boomLogo from '../images/awards/Boom.png'
import cannesLogo from '../images/awards/Cannes.png'
import clioLogo from '../images/awards/Clio.png'
import creativityLogo from '../images/awards/Creativity.png'
import directorsLibraryLogo from '../images/awards/DirectorsLibrary.png'
import dubaiLogo from '../images/awards/DubaiLynx.png'
import effieLogo from '../images/awards/Effie.png'
import filmShortageLogo from '../images/awards/FilmShortage.png'
import freeTheWorkLogo from '../images/awards/Freethework.png'
import grammyLogo from '../images/awards/Grammy.png'
import LBBLogo from '../images/awards/LBB.png'
import ndsLogo from '../images/awards/NewDirectorsShowcase.png'
import shortOfTheWeekLogo from '../images/awards/ShortOfTheWeek.png'
import sxswLogo from '../images/awards/SXSW.png'
import tribecaLogo from '../images/awards/Tribeca.png'
import vimeoLogo from '../images/awards/Vimeo.png'
import webbyLogo from '../images/awards/Webby.png'
import YDALogo from '../images/awards/YDA.png'

const logos = [
  {name: 'Amex', file: amexLogo},
  {name: 'Anheuser Busch', file: anheuserLogo},
  {name: 'Chevy', file: chevyLogo},
  {name: 'DHL', file: dhlLogo},
  {name: 'DoorDash', file: doordashLogo},
  {name: 'Facebook', file: facebookLogo},
  {name: 'Ford', file: fordLogo},
  {name: 'Google', file: googleLogo},
  {name: 'GQ', file: gqLogo},
  {name: 'Intuit', file: intuitLogo},
  {name: 'Jack Daniels', file: jackLogo},
  {name: 'Lyft', file: lyftLogo},
  {name: 'MasterCard', file: mcLogo},
  {name: 'Mazda', file: mazdaLogo},
  {name: 'McDonalds', file: mcDonaldsLogo},
  {name: 'Milk - Team USA', file: teamUsa},
  {name: 'M&Ms', file: mms},
  {name: 'Modelo', file: modeloLogo},
  {name: 'NBC Universal', file: nbcLogo},
  {name: 'NFL', file: nflLogo},
  {name: 'Nissan', file: nissanLogo},
  {name: 'Spotify', file: spotifyLogo},
  {name: 'White Claw', file: whiteClaw},
  {name: 'Wrangler', file: wranglerLogo},

];


const agencies = [
  {name: 'BBDO', file: bbdoLogo},
  {name: 'Cavalry', file: cavalryLogo},
  {name: 'Conde Nast', file: condeNast},
  {name: 'Cramer Krasselt', file: CramerKrasseltLogo},
  {name: 'DDB', file: ddbLogo},
  {name: 'Designory', file: designoryLogo},
  {name: 'Digitas', file: digitasLogo},
  {name: 'Doner', file: donerLogo},
  {name: 'FCB', file: fcbLogo},
  {name: 'Fluent 360', file: fluent360Logo},
  {name: 'Havas', file: havasLogo},
  {name: 'Hawkeye', file: hawkeyeLogo},
  {name: 'Leo Burnett', file: leoBurnettLogo},
  {name: 'McCann', file: mcannLogo},
  {name: 'Mono', file: monoLogo},
  {name: 'Ogilvy', file: ogilvyLogo},
  {name: 'OKRP', file: okrpLogo},
  {name: 'Peter Mayer', file: peterMayerLogo},
  {name: 'Publicis', file: publicisLogo},
  {name: 'TDH', file: tdhLogo},
  {name: 'VMLYR', file: vmlyrLogo},
  {name: 'Weber', file: weberShandwick},
  {name: 'JWT', file: wundermanThompson},
  {name: 'Zeno', file: Zeno},
]

const recognition = [
  {'name': 'ADC', file: adcLogo},
  {'name': 'AICP', file: aicpLogo},
  {'name': 'Boom', file: boomLogo},
  {'name': 'Cannes Lions', file: cannesLogo},
  {'name': 'Clio', file: clioLogo},
  {'name': 'Creativity Awards', file: creativityLogo},
  {'name': 'Directors Library', file: directorsLibraryLogo},
  {'name': 'Dubai Lynx', file: dubaiLogo},
  {'name': 'Effie', file: effieLogo},
  {'name': 'Film Shortage', file: filmShortageLogo},
  {'name': 'Free the Work', file: freeTheWorkLogo},
  {'name': 'Grammy', file: grammyLogo},
  {'name': 'LBB', file: LBBLogo},
  {'name': 'New Directors Showcase', file: ndsLogo},
  {'name': 'Short of the Week', file: shortOfTheWeekLogo},
  {'name': 'SXSW', file: sxswLogo},
  {'name': 'Tribeca Film Festival', file: tribecaLogo},
  {'name': 'Vimeo Staff Pick', file: vimeoLogo},
  {'name': 'Webby', file: webbyLogo},
  {'name': 'YDA', file: YDALogo},
]



const logoGroup = (logos) => {
  return (
    <div className='row'>        
      <Trail
      native
      from={{opacity: 0}}
      to={{opacity: 1}}
      keys={logos.map( logo => logo.name)}
      >
        {logos.map(logo => ({opacity}) => (
          <animated.div
            className='col-md-2 col-5'
            style={{
                opacity,
            }}
          >
            <img src={logo.file} alt={logo.name} />
          </animated.div>
          )
        )}
      </Trail>
    </div>
  )
}


const settings = {
  dots: true,
  fade: true,
  infinite: true,
  autoplay:false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: i => (
    <div>
      {i == 0 && 
        <div><h2>Brands</h2></div>
      }
      {i == 1 &&
        <div><h2>Agencies</h2></div>
      }
      {i == 2 &&
        <div><h2>Recognition</h2></div>
      }
    </div>
  )
};



const about = ({data}) => (
  <Layout>
    <Helmet>
      <title>Picture North | About Us & Our Collaborators</title>
      <meta name="description" content="Since 2008, Picture North has offered ideation, production and post-production solutions for advertising agencies, record labels and direct Collaborators. Our post-production services cover all aspects of finishing; including offline and online editorial, visual effects, color and mix." />
      <meta property="og:title" content='Picture North | About Us & Our Collaborators' />
      <meta property="og:description" content='Since 2008, Picture North has offered ideation, production and post-production solutions for advertising agencies, record labels and direct Collaborators. Our post-production services cover all aspects of finishing; including offline and online editorial, visual effects, color and mix.' />
      <meta property="og:image" content={data.site.siteMetadata.siteUrl+socialImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
      name="twitter:creator"
      content={'picture_north'}
      />
      <meta name="twitter:title" content='Picture North | About Us & Our Collaborators' />
      <meta name="twitter:description" content='Since 2008, Picture North has offered ideation, production and post-production solutions for advertising agencies, record labels and direct Collaborators. Our post-production services cover all aspects of finishing; including offline and online editorial, visual effects, color and mix.' />
      <meta name="twitter:image" content={data.site.siteMetadata.siteUrl+socialImage} />
    </Helmet>
    <div className="header text-center">
      <div className='container'>
        <div className='row '>
          <div className='col '>
            <div className='content about'>
              <h1>About</h1>
              <p>Since 2008, Picture North has offered ideation, production and post-production solutions for advertising agencies, record labels and direct clients. Our post-production services cover all aspects of finishing including offline and online editorial, visual effects, color and mix.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='hero-image' style={{backgroundImage: `url(${data.studioImage.childImageSharp.fluid.src})`}}>
      <SliderWrapper images={data.officeImages.edges} />
    </div>
    <div className="logo-carousel">
      <div className="slider-wrapper">
        <Slider {...settings} >
          <div>
            <div className='full-wrap'>
              <div className='container-fluid client-wrap pn-24-grid'>
                {logoGroup(logos)}
              </div>
            </div>
          </div>
          <div>
            <div className='full-wrap'>
              <div className='container-fluid client-wrap pn-24-grid'>
                {logoGroup(agencies)}
              </div>
            </div>
          </div>
          <div>
            <div className='full-wrap'>
              <div className='container-fluid client-wrap pn-24-grid'>
                {logoGroup(recognition)}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  </Layout>
    
)
export default about
export const clientLogos = graphql`
  query logos {
    site {
        siteMetadata {
            siteUrl
        }
    }
    studioImage: file(absolutePath: {regex: "/editing_suite.jpg/"}) {
    childImageSharp {
      fluid(maxWidth: 2000 ) {
        ...GatsbyImageSharpFluid
        }
      }
    } 
    allFile(filter: {relativePath: {regex: "/client_logos/v2/"}}) {
      edges {
        node {
          id
          name
            childImageSharp {
                fluid(maxWidth : 1000){
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
      }
    }
    officeImages: allFile(filter: {relativePath: {regex: "/office/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            id
            fluid(maxWidth: 2000, quality: 90){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`