import React from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderWrapper = props => {

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings} >
        {
          props.images.map( (image, i) =>{
            return <Img key={i} loading='eager' fluid={image.node.childImageSharp.fluid} />
          })
        }
      </Slider>
    </div>
  )
}

export default SliderWrapper;